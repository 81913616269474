/* *{
    font-family: "Montserrat";
} */
.container1 {
    display: flex;
    flex-wrap: wrap-reverse;
}
  
.txt{
    font-size:1.25em;
}
.txt1{
    font-size:1.25em;
    margin-top: 40px;
    margin-bottom: 40px;

}
.text1 {
    flex: 2.5;
    margin-top: 70px;
    /* margin-left: 80px; */
    text-transform: uppercase;
    font-weight: bold;
}

.text2 {
    flex: 2.5;
    margin-top: 70px;
    margin-left: 110px;
    text-transform: uppercase;
    font-weight: bold;

}

.image1 {
    flex: 2;
    margin-right: 80px;

}

.image2 {
    flex: 2;
    margin-right: 110px;

}

.container .img-home {
    height: 270px;
    width: 95%
}

@media (max-width: 768px) {
    .txt{
        font-size:0.99em;
    }
    
    .txt1{
        font-size:0.99em;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    
    .container .img-home {
        width: 100%;
        height: auto;

    }

    .container1 {
        flex-direction: column;
    }

    .text1 {
        flex: auto;
        margin-top: 30px;
        margin-left: 0px;

    }

    .text2 {
        flex: auto;
        margin-top: 30px;
        margin-left: 0px;

    }

    .image1 {
        flex: auto;
        margin-top: 10px;
        margin-right: 0px;

    }

    .image2 {
        flex: auto;
        margin-top: 10px;
        margin-right: 0px;

    }

    .about-image {
        width: 300px;
        height: auto;
        display: block;
    }
}

.image-container {
    position: relative;
    display: inline-block;
}

.about-image {
    width: 350px;
    height: 400px;
    display: block;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 95%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease-in-out;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #333333;
    text-decoration: none;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.image-container:hover .about-image {
    /* filter: brightness(70%); */
}

.image-container:hover .overlay {
    opacity: 1;
}

.image-container:hover .button {
    opacity: 1;
}